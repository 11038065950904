/**
 * Flexbox classes
 * @path: objects/flex
 */

// Parent flex classes
.o-flex {
    display: flex;
}

// left, right, center, justify, spaced
@each $alignment, $value in $flex-x-alignment {
    .o-flex--#{$alignment} {
        @include flex-align($x-alignment: $alignment);
    }
}

// top, bottom, middle, stretch
@each $alignment, $value in $flex-y-alignment {
    .o-flex--#{$alignment} {
        @include flex-align($y-alignment: $alignment);
    }
}

// Child flex classes
// Default browser settings for column
.o-flex--default {
    flex: 0 1 auto;
}

.o-flex--grow {
    flex: 1 0 auto;
}

// Shrinks and grows the column with equal padding to content
.o-flex--auto {
    flex: 1 1 auto;
}

// Shrinks and grows the column with equal width of content
// This is the default for the grid
.o-flex--even {
    flex: 1 1 0%;
}

// Doesn't grow or shrink the column
.o-flex--none {
    flex: 0 0 auto;
}

.o-flex--column {
    flex-direction: column;
}

.o-flex--row-reverse {
    flex-direction: row-reverse;
}

.o-flex--wrap {
    flex-wrap: wrap;
}

.o-flex--no-wrap {
    flex-wrap: nowrap;
}

.o-flex--align-self-center {
    align-self: center;
}

.o-flex--align-self-start {
    align-self: flex-start;
}

.o-flex--justify-end {
    justify-content: flex-end;
}

.o-flex--full-width {
    flex: 1 0 100%;
}

// Gaps
.o-gap-x-3 {
    column-gap: 2.4rem;
}

@include breakpoint(sm) {
    .o-sm-flex--justify {
        justify-content: space-between;
    }

    .o-sm-flex--default {
        flex: 0 1 auto;
    }
}